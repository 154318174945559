export const phoneRegexp =
  /^$|^(?=(?:\D*\d){1,15}$)((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?([0-9]{3,4}[ -]?[0-9]{3,4})?$/;
// /^(?=(?:\D*\d){1,15}$)((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?([0-9]{3,4}[ -]?[0-9]{3,4})?$/; // empty string

// export const passwordRegexp =
//   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[,.#?!@$%^&*-]).*$/;

// export const passwordRegexp =
//   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,\-./:;<=>±§№?@[\\\]^_`{|}~]).*$/;

export const passwordRegexp = /^(?=.*\d).{8,}$/;

export const currentCountryCode = "US";
